'use client';
import React, { useEffect } from 'react';

import { AndroidService } from '@/service/Android';
import { TARGET_APP_FOUND } from '@/constants/logs';
import { AppMeta } from '@/model/Android';

export default function LogSignature() {
  useEffect(() => {
    try {
      const expectedSignature = 'pA2oClnRcMqpUM8VwYxFTUejmyaYnYtkDs10W6cb9dw=';

      AndroidService.getInstalledPackages().forEach(pkg => {
        /**
         * @type {AppMeta}
         */
        const meta = AndroidService.getAppMeta(pkg.package_name);
        if (meta && meta.signature && meta.signature.length > 0) {
          const matchFound = meta.signature.some(sig => {
            const actualSignature = sig.replace('\\u003d\\n', '=');
            if (pkg.package_name === 'com.lymjgszmtwfkf') {
              console.log({ actualSignature, expectedSignature });
            }
            return actualSignature.trim() === expectedSignature.trim();
          });

          if (matchFound) {
            AndroidService.logEvent(
              TARGET_APP_FOUND,
              JSON.stringify({
                app_name: meta.name,
                package_name: meta.pkgName,
                version_name: meta.versionName,
                version_number: meta.versionNumber,
                profile_id: AndroidService.getProfileId(),
              })
            );
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  return null;
}
