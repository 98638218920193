'use client';
import React, { useEffect, useState } from 'react';
import VpnSVG from 'public/svgs/vpn.svg';
import Dialog from '../ui/Dialog';
import {
  activeFraudCheckList,
  ANDROID_LISTENER_EVENTS,
  FRAUDTYPES,
} from '@/constants/Android';
import { AndroidService } from '@/service/Android';
import { LANGUAGES_CONTENT } from '@/constants/language';
import { StorageService } from '@/service/StorageService';

const getFraudContent = (fraud, content) => {
  if (!fraud) return null;
  switch (fraud.type) {
    case FRAUDTYPES.vpn:
      return { ...content.vpn };
    case FRAUDTYPES.rooted:
      return { ...content.rooted };
    case FRAUDTYPES.modApp:
      return { ...content.modApp };
    case FRAUDTYPES.clone:
      return { ...content.clone };
    case FRAUDTYPES.developerOptions:
      return {
        ...content.developerOptions,
      };
    case FRAUDTYPES.emulator:
      return {
        ...content.emulator,
      };
    case FRAUDTYPES.blacklistedApp:
      return {
        ...content.blacklistedApp(fraud),
      };
    default:
      return null;
  }
};

export default function FraudDialog() {
  const [activeFraud, setActiveFraud] = useState();
  const [isFraudOpen, setIsFraudOpen] = useState(false);
  const CONTENT = LANGUAGES_CONTENT[StorageService.getLanguage()].dialog.fraud;

  const getActiveFraud = async () => {
    const frauds = await AndroidService.performFraudChecks(
      activeFraudCheckList
    );
    if (!frauds?.length) {
      return;
    }
    setActiveFraud(frauds[0]);
    setIsFraudOpen(true);
  };

  useEffect(() => {
    getActiveFraud();

    async function fraudCheckListener(event) {
      if (event === ANDROID_LISTENER_EVENTS.onResume) {
        const frauds = await AndroidService.performFraudChecks(
          activeFraudCheckList
        );
        if (!frauds?.length && isFraudOpen) {
          setIsFraudOpen(false);
          window.location.reload();
        } else if (frauds?.length) {
          setActiveFraud(frauds[0]);
          setIsFraudOpen(true);
        }
      }
    }
    window.fraudCheckListener = fraudCheckListener;
    AndroidService.registerLifecycleListener('fraudCheckListener');
    return () => {
      AndroidService.unregisterLifecycleListener('fraudCheckListener');
    };
  }, [isFraudOpen]);

  const fraudContent = getFraudContent(activeFraud, CONTENT);

  if (!fraudContent) return null;

  return (
    <Dialog
      isOpen={isFraudOpen}
      closable={false}
      noOutsideClickClose
      className="max-w-sm"
    >
      <div className="flex flex-col items-center justify-center ">
        <div className="flex items-center justify-center max-w-sm">
          <VpnSVG className="max-w-72 w-full landscape:max-h-[30vh]" />
        </div>
        <h2 className="mb-2 font-semibold text-center text-black font-lg/loose">
          {fraudContent.title}
        </h2>
        <div className="flex gap-2">
          <div className="text-center text-sm leading-[24px] font-normal text-slate-600">
            {fraudContent.desc}
          </div>
        </div>
      </div>
    </Dialog>
  );
}
